namespace gotoAndPlay {

    export class Notification {
        static initSelector: string = '.notification';

        public element: JQuery;
        public closeButton: JQuery;

        private id: number;
        private language: number;
        private isCookieNotification: boolean;
        private cookieName: string;

        constructor(element: HTMLElement) {
            this.element = $(element);
            this.id = this.element.data('id');
            this.language = this.element.data('lang');
            this.closeButton = this.element.find('.notification__close-button');
            this.isCookieNotification = this.element.hasClass('notification--cookie');
            this.cookieName = this.isCookieNotification ? 'krematoorium_cookie_notification_' : 'krematoorium_notification_';

            this.init();
        }

        init(): void {
            if (Cookie.get(this.cookieName + this.language + '_' + this.id) !== 'closed') {
                this.show();
            } else {
                this.element.remove();
            }

            this.closeButton.on('click', this.close.bind(this));
            this.checkDeviceHandler();

            $(window).on('resize', this.checkDeviceHandler.bind(this));
        }

        close(event: JQueryEventObject): void {
            event.preventDefault();

            Cookie.set(this.cookieName + this.language + '_' + this.id, 'closed', this.isCookieNotification ? (24 * 365) : 24);

            this.hide();
        }

        show(): void {
            this.element.addClass('is-visible');
        }

        hide(): void {
            this.element.on('transitionend', (event: JQueryEventObject): void => {
                if (event.target === event.currentTarget && (event.originalEvent as TransitionEvent).propertyName === 'transform') {
                    this.element.remove();
                    this.checkDeviceHandler();
                }
            });

            this.element.removeClass('is-visible');
        }

        checkDeviceHandler(): void {
            const notifications: JQuery = $('.notification');

            if ($(window).innerWidth() < Helpers.bp.md) {
                notifications.each((index: number, elem: HTMLElement) => {
                    if (index == 0) {
                        $(elem).addClass('is-visible');
                    } else {
                        $(elem).removeClass('is-visible');
                    }
                });
            } else {
                notifications.each((index: number, elem: HTMLElement) => {
                    $(elem).addClass('is-visible');
                });
            }
        }
    }

    $(Notification.initSelector).each((index: number, element: HTMLElement) => {
        $(element).data('notification', new Notification(element));
    });

}
