// import('import/js/datepicker.js');
// import('import/js/i18n/datepicker.en.js');
// import('import/js/i18n/datepicker.et.js');
// import('import/js/i18n/datepicker.ru.js');
// import('import/js/auto-complete.js');

declare let autoComplete: any;

namespace gotoAndPlay {

    let datepickers = [];

    export function datepickerInit() {
        let mainInit = $.Deferred();
        $('.js-datetimepicker input:visible').each(function() {
            let val = $(this).val();
            let d: Date;
            let element = $(this);
            let maxDate = null;
            let minDate = null;
            let selectedDate = '';
            if (val && element.hasClass('js-modal-datepicker')) {
                let day = parseInt(val.split('.')[0]);
                let month = parseInt(val.split('.')[1]) - 1;
                let year = parseInt(val.split('.')[2].split(' / ')[0]);
                let hours = parseInt(val.split(' / ')[1].split(':')[0]);
                let minutes = parseInt(val.split(':')[1]);
                d = new Date(year, month, day, hours, minutes, 0, 0);
            } else if (val && element.hasClass('js-timetable-datepicker')) {
                let day = parseInt(val.split('.')[0]);
                let month = parseInt(val.split('.')[1]) - 1;
                let year = parseInt(val.split('.')[2]);
                d = new Date(year, month, day, 0, 0, 0, 0);
            } else if (element.hasClass('js-log-from-datepicker')) {
                if (val) {
                    let day = parseInt(val.split('.')[0]);
                    let month = parseInt(val.split('.')[1]) - 1;
                    let year = parseInt(val.split('.')[2]);
                    d = new Date(year, month, day, 0, 0, 0, 0);
                    $.when(mainInit).done(function() {
                        element.trigger('change');
                    });
                } else {
                    d = new Date();
                    selectedDate = ' ';
                }
                maxDate = new Date();
            } else if (element.hasClass('js-log-to-datepicker')) {
                if (val) {
                    let day = parseInt(val.split('.')[0]);
                    let month = parseInt(val.split('.')[1]) - 1;
                    let year = parseInt(val.split('.')[2]);
                    d = new Date(year, month, day, 0, 0, 0, 0);
                    $.when(mainInit).done(function() {
                        element.trigger('change');
                    });
                } else {
                    d = new Date();
                    selectedDate = ' ';
                }
            } else {
                d = new Date();
                d.setHours(d.getHours() + 1);
                d.setMinutes(0);
                d.setSeconds(0);
            }

            let parent = $(this).parents('.js-datetimepicker');
            if (parent.data('html')) {
                parent.html('' + parent.data('html'));
                element = parent.find('input:visible');
            } else {
                parent.data('html', parent.html());
            }
            datepickers.push(element.datepicker({
                timepicker: element.data('time'),
                times: element.data('times'),
                language: lang,
                startDate: d,
                selectedDate: (selectedDate ? selectedDate : d),
                minDate: (isAdmin ? minDate : d), // Now can select only dates, which goes after today
                maxDate: maxDate,
                minView: 'days', // Do not allow to select months or years
                position: 'bottom center',
                dateTimeSeparator: ' / ',
                dateFormat: 'dd.mm.yyyy',
                showOtherMonths: false,
                minHours: 9,
                maxHours: 16,
                minutesStep: 30,
                minMinutes: 0,
                maxMinutes: 30,
                autoClose: !element.data('time'),
                navTitles: {
                    days: 'MM <i>yyyy</i>',
                    months: 'yyyy',
                    years: 'yyyy1 - yyyy2',
                },
                todayButton: isAdmin,
                prevHtml: '<svg><path d="M 15,7 l -5,5 l 5,5"></path></svg>',
                nextHtml: '<svg><path d="M 12,7 l 5,5 l -5,5"></path></svg>',
                onSelect: function() {
                    if (isAdmin) {
                        element.trigger('change');
                    }
                },
            }));
            if (val) {
                element.val(val);
            }
        });

        mainInit.resolve();
    }

    export class NumField {
        public static add(event) {
            const element = $(event.currentTarget).parents('.js-form-textfield--number');
            const input   = element.find('input[type="number"]');
            const max     = element.data('max');
            event.stopPropagation();
            if (!max || parseInt(input.val()) < max) {
                input.val(parseInt(input.val()) + 1).trigger('change');
            }
        }

        public static subtract(event) {
            const element = $(event.currentTarget).parents('.js-form-textfield--number');
            const input   = element.find('input[type="number"]');
            const min     = element.data('min') ? element.data('min') : 0;
            event.stopPropagation();
            if (parseInt(input.val()) > min) {
                input.val(parseInt(input.val()) - 1).trigger('change');
            }
        }
    }

    $(document).on('click', '.js-form-add', NumField.add);
    $(document).on('click', '.js-form-subtract', NumField.subtract);
    datepickerInit();
    $(document).on('reload', datepickerInit);
    $(window).on('load', datepickerInit);
}
