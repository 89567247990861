// import('import/js/modaal.js');

namespace gotoAndPlay {

    export class Modaal {
        static init() {
            $('.js-modaal:visible').each(function() {
                if (!$(this).hasClass('is-inited')) {
                    $(this).addClass('is-inited');
                    $($(this).attr('href')).data('modal', $(this).modaal({
                        after_open: Modaal.afterOpenModal,
                        before_close: Modaal.beforeCloseModal,
                        after_close: Modaal.afterCloseModal,
                        background: '#ffffff',
                        custom_class: $(this).data('custom-class'),
                        overlay_opacity: 0.9,
                    }));
                }
            });
        }

        static afterOpenModal(): void {
            $(document).trigger('reload');
            $(document).trigger('modaal_open');
            datepickerInit();
            modalOpening = false;
            const modaalWrapper: JQuery = $(document).find('.modaal-wrapper');
            const checkbox = modaalWrapper.find('.js-modal-item-checkbox:checked');
            const isProductModal: boolean = modaalWrapper.find('.product-grid--modaal').length > 0;
            if (isProductModal) {
                modaalWrapper.css('overflow', 'hidden');
            }
            if (checkbox.length) {
                Helpers.scrollToTarget(checkbox.parent(), $('.js-perfect-scrollbar:visible').offset().top, '.js-perfect-scrollbar:visible');
            }
        }

        static beforeCloseModal() {
            $(document).trigger('modaal_close');
        }

        static afterCloseModal() {
            $(document).trigger('modaal_closed');
        }

        static closeModal(element: JQuery) {
            const modaalScope: string = element.closest('.modaal-wrapper').attr('id');
            $(document).find(`[data-modaal-scope="${modaalScope}"]`).modaal('close');
        }
    }

    // Init on document ready
    $(function() {

        $(document).on('click', '.js-modal', function(event) {
            event.preventDefault();
        });

        $(document).on('click', '.js-modal-close', function(event) {
            event.preventDefault();
            if ($('.js-modaal').length && $('.js-modaal').data('modaal-scope')) {
                $('.js-modaal').modaal('close');
            }
        });

        $(document).on('click', '.modaal-close', function (event) {
            event.preventDefault();
            const target: JQuery = $(event.currentTarget);
            const modaalScope: string = target.closest('.modaal-wrapper').attr('id');

            $(document).find(`[data-modaal-scope="${modaalScope}"]`).modaal('close');
        });

        $(document).on('click', '.js-selected-product', (event) => {
            const target = $(event.currentTarget);
            const modal = target.parents('.modaal-wrapper');
            modal.find('input.js-modal-item-radio[data-id="' + target.data('id') + '"]').trigger('change');
        });

        $(document).on('change', '.js-modal-item-radio', function(event) {
            event.preventDefault();
            const target: JQuery = $(event.currentTarget);
            summary.changeProductOption(target);
            Modaal.closeModal(target);
        });

        Modaal.init();
        $(document).on('reload', Modaal.init);
    });

}
