namespace gotoAndPlay {

    $(document).on('submit', '.js-order-details-form', function(event) {
        $('[type="submit"]', this).addClass('is-loading');
    });

    $(document).on('submit', '.js-quick-draft-form', function(event) {
        $('[type="submit"]', this).addClass('is-loading');
    });

    $(document).on('click', '.js-confirm-order', (event) => {
        event.preventDefault();
        const form: HTMLFormElement = $('.js-order-form')[0] as HTMLFormElement;
        let error: boolean = false;
        let hasScrolledToError: boolean = false;

        $('.form-textfield__input select').each(function() {
            const input: JQuery = $(this);
            let selected = input.children('option:selected').val();
            let parent = input.parents('.js-form-select');
            if (selected === '') {
                parent.addClass('is-invalid');
                error = true;
                if (!hasScrolledToError) {
                    scrollToInvalidInput(parent);
                    hasScrolledToError = true;
                }
            } else {
                parent.removeClass('is-invalid');
            }
        });

        if (!form.reportValidity()) {
            error = true;
        }

        if (!error) {
            $(event.currentTarget).prop('disabled', true).addClass('is-loading');
            const formOrder: JQuery = $('.js-order-form');
            formOrder.trigger('submit');
        }
    });

    $(document).on('click', '.js-submit-order-details', (event) => {
        event.preventDefault();
        const form: JQuery = $('.js-order-details-form');
        let error: boolean = false;
        let hasScrolledToError: boolean = false;

        form.find('input[required]').each((index: number, element: HTMLInputElement) => {
            const input: JQuery = $(element);
            if (input.val() === '') {
                input.parents('.form-textfield').addClass('is-invalid');
                error = true;

                if (!hasScrolledToError) {
                    scrollToInvalidInput(input);

                    hasScrolledToError = true;
                }
            } else {
                input.parents('.form-textfield').removeClass('is-invalid');
            }
        });

        $(['has_stimulator', 'valuables']).each((index, field) => {
            if (!form.find('input[name="' + field + '"]:checked').length) {
                const target: JQuery = form.find('input[name="' + field + '"]');

                target.parents('.form-radio__container').addClass('is-invalid');
                error = true;

                if (!hasScrolledToError) {
                    scrollToInvalidInput(target.closest('.form-radio__container'));

                    hasScrolledToError = true;
                }
            } else {
                form.find('input[name="' + field + '"]').parents('.form-radio__container').removeClass('is-invalid');
            }
        });

        const fileField: JQuery = form.find('input[type="file"]');
        const fileName: string = fileField.val();
        if (fileName && fileName != '') {
            const fileType: string = fileName.split('.')[(fileName.split('.').length - 1)].toLowerCase();
            if (['jpg', 'jpeg', 'png', 'pdf'].indexOf(fileType) == -1 || (fileField[0] as HTMLInputElement).files[0].size > (parseInt(fileField.attr('size')) * 1024 * 1024)) {
                fileField.parents('.form-file').addClass('is-invalid');
                error = true;

                if (!hasScrolledToError) {
                    scrollToInvalidInput(fileField);

                    hasScrolledToError = true;
                }
            } else {
                fileField.parents('.form-file').removeClass('is-invalid');
            }
        } else {
            fileField.parents('.form-file').removeClass('is-invalid');
        }

        if (!error) {
            $(event.currentTarget).prop('disabled', true).addClass('is-loading');
            form.trigger('submit');
        }

    });

    function scrollToInvalidInput(input: JQuery): void {
        const inputHeight: number = input.outerHeight();
        const inputOffsetFromTop: number = input.offset().top;
        const additionalOffset: number = 50;

        $('html, body').animate({
            scrollTop: inputOffsetFromTop - inputHeight - additionalOffset,
        }, 300);
    }

    if ($('body').hasClass('page-template-template-order')) {
        $(document).on('change', '.js-transport-service', (event: JQueryEventObject) => {
            const target: JQuery = $(event.currentTarget);
            const parentRow: JQuery = target.parents('.js-pricelist-item');
            const distance = parentRow.find('.js-distance').val() ? Math.round(parseFloat(parentRow.find('.js-distance').val()) / 1000 * 2) : 0;
            const address = parentRow.find('input[name="body_address"]').val();
            let data = {
                action: 'get_trasnport_service_price',
                serviceName: target.val(),
                distance: distance,
            };
            $.post(ajaxPath, data, (response) => {
                if (response['price'] || response['price'] === 0) {
                    const price = response['price'] + '€';
                    parentRow.find('.js-pricelist-item--number-price').html(price).data('amount', response['amount'] ? response['amount'] : distance).data('service', response['service']).data('address', address).trigger('change');
                    $('#summary-item-' + parentRow.data('service')).find('.summary__row-value').html(price);
                }
                parentRow.find('.js-google-autocomplete').removeClass('is-loading');
                $('.js-confirm-order').prop('disabled', false).removeClass('is-disabled');
            });
        });
    }

}
