namespace gotoAndPlay {

    class Filter {

        private current: string;

        constructor() {
            $(document).on('click', '.js-product-filter a', this.onClick.bind(this));
            $(document).on('change', '.js-product-filter select', this.onSelect.bind(this));
        }

        onClick(event: JQueryEventObject) {
            event.preventDefault();
            this.current = $(event.currentTarget).attr('href').substr(1);
            this.update();
        }

        onSelect(event: JQueryEventObject) {
            this.current = $(event.currentTarget).val();
            this.update();
        }

        update() {
            $('.js-product-filter .is-active').removeClass('is-active');
            $('.js-product-filter a[href="#' + this.current + '"]').parent().addClass('is-active');
            $('.js-product-filter select').val(this.current);
            $('.modaal-wrapper.modaal--large').find('.product-grid__scroll').animate({scrollTop: 0});
            if (!this.current) {
                $('.js-product-grid').show();
            } else {
                $('.js-product-grid').hide();
                $('.js-product-grid .js-product[data-material="' + this.current + '"]').parent().show();
            }
        }

    }

    export let productFilter = new Filter();

}
