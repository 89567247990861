// import '/import/js/tooltipster.bundle.min.js';

namespace gotoAndPlay {
    $(document).ready(function () {
        if ($('.c-tooltip').length > 0) {
            $('.c-tooltip').tooltipster({
                theme: 'tooltipster-custom',
                distance: -4,
            });
        }

        if ($('.js-mandatory-service-tooltip').length > 0) {
            $('.js-mandatory-service-tooltip').tooltipster({
                theme: 'tooltipster-custom',
                distance: 28,
            });
        }

        if ($('.js-summary-tooltip').length > 0) {
            $('.js-summary-tooltip').tooltipster({
                theme: 'tooltipster-custom tooltipster-help',
                trigger: 'click',
                distance: 28,
                contentAsHTML: true,
            });
        }
    });
}
