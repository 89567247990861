namespace gotoAndPlay {

    export interface IAjaxPostParams {
        action: string;
        nonce?: string;
        [propName: string]: any;
    }

    export class Ajax {

        static post(postData: IAjaxPostParams, url?: string, postDataType?: string): JQueryXHR {
            if (!postData.nonce && nonce) {
                postData.nonce = nonce;
            }
            if (!url && ajaxPath) {
                url = ajaxPath;
            }
            if (!postDataType) {
                postDataType = 'json';
            }
            const xhr = $.ajax({
                data: postData,
                dataType: postDataType,
                type: 'POST',
                url,
            });
            return xhr;
        }

        static get(pageUrl: string): JQueryXHR {
            return $.ajax({
                type: 'GET',
                url: pageUrl,
            });
        }

        static loadScript(url: string): JQueryXHR {
            if (Helpers.isURL(url)) {
                return $.getScript(url);
            } else {
                return $.getScript(templatePath + '/inc/js/import/' + url + '.' + version + '.js');
            }
        }
    }

}
