namespace gotoAndPlay {

    export class Summary {
        private items: SummaryItem[] = [];

        constructor() {
            this.init();
        }

        init() {
            $('.js-pricelist-item').each((index, elem) => {
                let summaryItem = new SummaryItem(elem as HTMLElement);
                this.items.push(summaryItem);
            });
        }

        showSummaryItems() {
            this.items.forEach(function (item) {
                if (item.isChecked()) {
                    item.show();
                }
            });
            this.showTotalPrice();
        }

        updateSummaryItem(id, operation, htmlElement = null, amount = null) {
            let itemIndex = this.getItemIndex(id);
            let item: SummaryItem = this.items[itemIndex];
            if (item != null) {
                switch (operation) {
                    case 'check':
                        item.setChecked($('input[type="checkbox"]', item.getElement()).is(':checked'));
                        if (item.isChecked()) {
                            item.show();
                        } else {
                            item.hide();
                        }
                        break;
                    case 'changeItem':
                        item.hide();
                        item = new SummaryItem(htmlElement);
                        this.items[itemIndex] = item;
                        if (item.isChecked()) {
                            item.show();
                        }
                        break;
                    case 'amountChange':
                        item.hide();
                        item.setAmount(amount);
                        $('.summary__row-value', $('#summary-item-' + id))[0].innerHTML = item.getFormatedPrice();
                        $('.js-summary__row-count', $('#summary-item-' + id))[0].innerHTML = item.getAmount();
                        if (item.getAmount() == 1) {
                            $('.js-summary__row-unit--plural', $('#summary-item-' + id)).addClass('h-hidden');
                            $('.js-summary__row-unit--singular', $('#summary-item-' + id)).removeClass('h-hidden');
                        } else {
                            $('.js-summary__row-unit--plural', $('#summary-item-' + id)).removeClass('h-hidden');
                            $('.js-summary__row-unit--singular', $('#summary-item-' + id)).addClass('h-hidden');
                        }

                        item.setTitle(($('.summary__row-title', $('.summary__row#summary-item-' + item.getId())).text()));
                        if (item.isChecked()) {
                            item.show();
                        }
                        break;
                    case 'transportServiceChange':
                        item.hide();
                        item.setAmount(amount);
                        item.setPrice(parseFloat($(htmlElement).html().replace(/€/, '')));
                        item.show();
                        item.setUsedTransportService($(htmlElement).data('service'));
                        item.setAddress($(htmlElement).data('address'));
                        break;
                }
            }
            this.showTotalPrice();
            initSelect();
        }

        getItemIndex(id) {
            let index = 0;
            while (index < this.items.length) {
                if (this.items[index].getId().split('-')[0] == id) {
                    break;
                }
                index++;
            }
            return index;
        }

        getItemById(id) {
            let resItem = null;
            this.items.forEach(function (item) {
                if (item.getId().split('-')[0] == id) {
                    resItem = item;
                    return;
                }
            });
            return resItem;
        }

        getTotalPrice() {
            let totalPrice: number = 0;
            this.items.forEach(function (item) {
                if (item.isChecked()) {
                    totalPrice += item.getPrice() ? parseFloat(item.getPrice().toFixed(2)) : 0;
                }
            });
            return parseFloat(totalPrice.toFixed(2));
        }

        showTotalPrice() {
            if ($('.js-summary__row--total .summary__row-value').length > 0) {
                $('.js-summary__row--total .summary__row-value').html(this.getTotalPrice().toString() + '€');
            }
        }

        changeProductOption(target) {
            let itemIndex = this.getItemIndex(target.data('service'));
            if (this.items[itemIndex]) {
                this.items[itemIndex] = this.items[itemIndex].update(target);
            }

            this.showSummaryItems();
        }

        changeProductOptions(target) {
            let itemIndex = this.getItemIndex(target.data('service'));
            if (this.items[itemIndex]) {
                this.items[itemIndex] = this.items[itemIndex].updateMultiSelect();

                Modaal.closeModal(target);
            }

            this.showSummaryItems();
        }

        getItems() {
            return this.items.map((item) => {
                return {
                    id: item.id,
                    option: item.option,
                    checked: item.checked,
                    price: item.price,
                    unitPrice: item.unitPrice,
                    amount: item.amount,
                    description: item.description,
                    title: item.title,
                    serviceType: item.serviceType,
                    usedTransportService: item.usedTransportService,
                    address: item.address,
                    selectedThumbnailClass: item.selectedThumbnailClass,
                    checkboxClass: item.checkboxClass,
                    selectedProductId: item.selectedProductId,
                    mandatory: item.mandatory,
                    multiSelect: item.multiSelect,
                };
            });
        }
    }

    class SummaryItem {
        public id;
        public option;
        public checked;
        public price;
        public unitPrice;
        public amount;
        public description;
        public title;
        public serviceType;
        public multiSelect;
        public element;
        public usedTransportService;
        public address;
        public selectedThumbnailClass: string = '.js-selected-thumbnail';
        public checkboxClass: string = '.js-pricelist__table-check';
        public modal: JQuery;
        public selectedProductId: number;
        public mandatory: boolean;
        public initialized: boolean = false;

        constructor(rootHtmlElement: HTMLElement) {
            this.init(rootHtmlElement);
            this.element.find(this.selectedThumbnailClass).on('click', this.openModal.bind(this));
            this.initialized = true;
        }

        init(rootHtmlElement) {
            this.element = $(rootHtmlElement);
            let input: HTMLInputElement = $('.js-form-check input', this.element).get(0) as HTMLInputElement;
            this.mandatory = this.element.data('mandatory');
            if (input != null) {
                this.title = '';
                this.checked = input.checked;

                if ($('.js-form-select select', this.element).length) {
                    this.id = input.id.replace('checkbox-', '') + '-' + $(this.element).find('.js-form-select select option:selected').data('id');
                    this.serviceType = 'service_varied';
                    if ($('.js-form-select select option:selected', this.element).attr('data-price')) {
                        this.price = parseFloat($('.js-form-select select option:selected', this.element).attr('data-price').split('€')[0]);
                    } else {
                        this.price = 0;
                    }
                    let options = [];
                    $('.js-form-select select option', this.element).each(function () {
                        if (!($(this).val() == '')) {
                            options.push($(this).text());
                        }
                    });
                    let desc = $('.js-pricelist-item-description', this.element);
                    if (desc.length) {
                        desc.html((desc.data('default') ? desc.data('default') + '<br>' : '') + options.join(', '));
                    }
                    if (this.checked) {
                        desc.hide();
                    }
                } else if ($('.js-form-textfield--number', this.element).length) {
                    this.id = input.id.replace('checkbox-', '');
                    this.serviceType = 'service_quantity';
                    this.unitPrice = parseFloat(this.element.data('price'));
                    this.amount = parseFloat($('.js-form-textfield__input--number', this.element).val());
                } else if ($('.js-modaal', this.element).length) {
                    this.id = input.id.replace('checkbox-', '');
                    this.serviceType = 'service_option';
                    this.multiSelect = this.element.data('multiselect');
                    const modalId = $('.js-modaal', this.element).attr('href');
                    this.modal = $(modalId);
                    if (this.multiSelect) {
                        this.updateMultiSelect();
                    } else {
                        if (this.modal.find('input[type="radio"]:checked').length) {
                            const selectedInput: JQuery = this.modal.find('input[type="radio"]:checked');
                            this.setDefaultServiceOption(selectedInput);
                        }
                    }
                } else if (this.element.data('type') && this.element.data('type') === 'transport') {
                    this.id = input.id.replace('checkbox-', '');
                    this.serviceType = 'transport';
                } else {
                    this.id = input.id.replace('checkbox-', '');
                    this.serviceType = 'service_simple';
                    this.price = parseFloat($(this.element).data('price'));
                }

                if (!this.title && this.id) {
                    this.title = $('.summary__row-title', $('.summary__row#summary-item-' + this.id)).text();
                }

                $(input).on('change', (event) => {
                    this.openMultiselect();
                });
            }
        }

        openMultiselect() {
            if (this.multiSelect && !this.option.length) {
                modalOpening = true;
                this.openModal();
            }
        }

        setDefaultServiceOption(option) {
            this.setOption(option.val());
            this.setTitle(option.data('title'));
            this.setDescription(option.data('description'));
            this.setPrice(option.data('price'));
            if (this.mandatory) {
                this.setChecked(true);
            }
            this.moveSelectedProductToBeginning(option, true);
        }

        getId() {
            return this.id;
        }

        getElement() {
            return this.element;
        }

        getAmount() {
            return this.amount;
        }

        setAmount(amount) {
            this.amount = amount;
        }

        setPrice(price) {
            this.price = price;
        }

        setTitle(title) {
            this.title = title;
        }

        getTitle() {
            return this.title;
        }

        setDescription(value) {
            this.description = value;
        }

        getPrice() {
            if (this.unitPrice && this.amount) {
                return parseFloat((this.unitPrice * this.amount).toFixed(2));
            }
            return this.price ? parseFloat(this.price.toFixed(2)) : null;
        }

        getFormatedPrice() {
            return this.getPrice().toString() + '€';
        }

        setOption(option) {
            this.id = this.id.split('-')[0] + '-' + option;
            this.option = option;
        }

        setOptions() {
            const modalScope = $('a[href="' + this.modal.selector + '"]').data('modaal-scope');
            let modal;
            if (modalScope) {
                modal = $('#' + modalScope);
            } else {
                modal = $(this.modal.selector);
            }

            let options = [];
            modal.find('.js-modal-item-checkbox:checked').each((index, element) => {
                const $element = $(element);
                options.push({
                    id: $element.val(),
                    title: $element.data('title'),
                    description: $element.data('description'),
                    price: $element.data('price'),
                    amount: $element.parents('.js-product').find('.js-form-textfield__input--number').val(),
                });
            });

            this.option = options;
        }

        isChecked() {
            return this.checked;
        }

        setChecked(value) {
            const change = this.checked != value;
            this.checked = value;
            let desc = $('.js-pricelist-item-description', this.element);
            let btn = $('.js-modaal', this.element);
            if (btn.length && !btn.data('default')) {
                btn.data('default', btn.html());
            }
            if (this.checked) {
                if (!modalOpening) {
                    this.element.find('.js-selected-thumbnail').removeClass('h-hidden');
                }
                $('input[type="checkbox"]', this.element).prop('checked', 'checked');
                if (this.serviceType == 'service_option') {
                    if (!this.option) {
                        this.checked = false;
                        $('input[type="checkbox"]', this.element).removeProp('checked');
                        btn.trigger('click');
                    } else if (!modalOpening) {
                        $('.js-pricelist-item--number-price', this.element).html(this.getFormatedPrice());
                        // btn.html(strings.change); KREM-614
                        desc.html(this.title + (this.description ? ' (' + this.description + ')' : ''));
                    }
                } else if (this.serviceType == 'service_varied') {
                    desc.hide();
                }
            } else {
                this.element.find('.js-selected-thumbnail').addClass('h-hidden');
                $('input[type="checkbox"]', this.element).removeProp('checked');
                if (this.serviceType == 'service_option') {
                    btn.html(btn.data('default'));
                    desc.html(desc.data('default'));
                } else if (this.serviceType == 'service_varied') {
                    desc.show();
                }
            }
        }

        moveSelectedProductToBeginning(option, init = false) {
            let selectedProduct;
            let modal;
            if (this.modal.data('first') || init) {
                modal = this.modal;
                selectedProduct = this.modal.find('.js-selected-product');
            } else {
                modal = $('#' + $('a[href="#' + this.modal.attr('id') + '"]').data('modaal-scope'));
                selectedProduct = modal.find('.js-selected-product');
            }

            modal.find('.js-product[data-id="' + this.modal.data('selectedProductId') + '"]').parent().removeClass('h-hidden');
            selectedProduct.attr('data-id', option.data('id')).html(option.next().clone()).removeClass('h-hidden');
            modal.find('.js-product[data-id="' + option.data('id') + '"]').parent().addClass('h-hidden');
            this.modal.data('selectedProductId', option.data('id'));
            this.selectedProductId = option.data('id');
        }

        setUsedTransportService(serviceId) {
            this.usedTransportService = serviceId;
        }

        setAddress(address) {
            this.address = address;
        }

        show() {
            if (this.multiSelect) {
                $(this.option).each((index, option) => {
                    const product = $('#summary-item-' + this.id + '-' + option['id']);
                    const price = parseFloat((parseFloat(option['price']) * parseInt(option['amount'])).toFixed(2)).toString() + '€';
                    product.removeClass('h-hidden');
                    product.find('.summary__row-value').text(price);
                    if (option['amount'] > 1) {
                        product.find('.js-summary__row-count').removeClass('h-hidden').find('span').text(option['amount']);
                    } else {
                        product.find('.js-summary__row-count').addClass('h-hidden');
                    }
                });
            } else {
                $('#summary-item-' + this.id).removeClass('h-hidden');
            }
        }

        hide() {
            if (this.multiSelect) {
                $('[id^=summary-item-' + this.id + '-]').addClass('h-hidden');
            } else {
                $('#summary-item-' + this.id).addClass('h-hidden');
            }
        }

        update(target) {
            this.hide();
            this.setOption(target.data('id'));
            this.setPrice(parseFloat(target.data('price')));
            this.setDescription(target.data('description'));
            this.setTitle(target.data('title'));
            $(document).one('modaal_closed', () => {
                this.moveSelectedProductToBeginning(target);
            });
            let srcset = target.next().find('img').attr('srcset');
            this.element.find(this.selectedThumbnailClass).data('product', target.data('id'));
            this.element.find(this.selectedThumbnailClass).find('img').attr('srcset', srcset).attr('data-srcset', srcset).data('srcset', srcset);
            if (!pageLoad || this.checked) {
                this.setChecked(true);
            }

            return this;
        }

        updateMultiSelect() {
            this.hide();
            this.setOptions();
            let price = 0;
            let description = '';
            if (this.option.length) {
                if (this.option.length > 1) {
                    $(this.option).each((index, option) => {
                        price += (parseFloat(option['price']) * parseInt(option['amount']));
                        description += option['title'] + ' <b>(' + option['amount'] + this.element.data('pcs') + ')</b><br>';
                    });
                    this.element.find(this.selectedThumbnailClass).hide();
                } else {
                    const option = this.option[0];
                    price += (parseFloat(option['price']) * parseInt(option['amount']));
                    description = option['title'] + ' (' + option['description'] + ')';
                    if (option['amount'] > 1) {
                        description += ' <b>(' + option['amount'] + this.element.data('pcs') + ')</b>';
                    }
                    let srcset = $('#' + option['id']).next().find('img').attr('srcset');
                    this.element.find(this.selectedThumbnailClass).data('product', option['id']).show();
                    this.element.find(this.selectedThumbnailClass).find('img').attr('srcset', srcset).attr('data-srcset', srcset).data('srcset', srcset);
                }
                this.setPrice(price);
                this.setTitle(description);
                if ((!pageLoad && this.initialized) || this.checked) {
                    this.setChecked(true);
                }
            } else {
                this.setChecked(false);
                $('.js-pricelist-item--number-price', this.element).html($('.js-pricelist-item--number-price', this.element).data('default'));
            }

            return this;
        }

        openModal() {
            this.element.find('.js-modaal').trigger('click');
        }

    }

    export let summary = new Summary();
    export let pageLoad = false;
    export let modalOpening = false;

    $(window).on('load', function () {
        if (orderSessionData) {
            let sessionOrder = JSON.parse(orderSessionData);
            pageLoad = true;
            let index;
            for (index in sessionOrder) {
                let element = sessionOrder[index];
                switch (element.serviceType) {
                    case 'service_quantity':
                        $('#js-form-textfield__input--number-' + element.id).val(element.amount).trigger('change');
                        break;

                    case 'service_varied':
                        let select = $('#select-' + element.id.split('-')[0]);
                        select.val(select.find('option[data-id="' + element.id.split('-')[1] + '"]').val()).trigger('change');
                        break;

                    case 'service_option':
                        summary.changeProductOption($('input[type=radio][name=' + element.id.split('-')[0] + '-service-option][value=' + element.id.split('-')[1] + ']'));
                        break;

                    case 'transport':
                        if (element.checked == 'true') {
                            const pricelistItem = $('.js-pricelist-item[data-service="' + element.id + '"]');
                            const priceElement = pricelistItem.find('.js-pricelist-item--number-price');
                            priceElement.html(element.price + '€').data('address', element.address).data('service', element.usedTransportService);
                            pricelistItem.find('input[name="body_address"]').val(element.address);
                            if ($('#summary-item-' + element.id).length) {
                                $('#summary-item-' + element.id).find('.summary__row-value').html(element.price + '€');
                            }
                            summary.updateSummaryItem(element.id, 'transportServiceChange', priceElement[0], element.amount);
                        }
                        break;
                }
                if (element['checked'] == 'true') {
                    let id = element.id.split('-')[0];
                    const checkbox = $('.js-pricelist-item[data-service=' + id + '] input[type=checkbox]');
                    if (!checkbox.prop('checked')) {
                        checkbox.prop('checked', 'checked').trigger('change');
                    }
                }
            }
            pageLoad = false;
        }
    });

    summary.showSummaryItems();

    $(document).on('change', '.js-pricelist-item input.form-check__input', function () {
        if (!this.disabled) {
            summary.updateSummaryItem(this.id.replace('checkbox-', ''), 'check');
        }
    });

    $(document).on('change', '.js-pricelist-item .js-form-textfield__input--number', function () {
        let amount = this.value;
        summary.updateSummaryItem(this.id.replace('js-form-textfield__input--number-', ''), 'amountChange', null, amount);
    });

    $(document).on('change', '.js-pricelist-item .js-form-select select', function () {
        summary.updateSummaryItem(this.id.replace('select-', ''), 'changeItem', $(this).parents('.js-pricelist-item').get(0));
    });

    $(document).on('change', '.js-pricelist-item[data-type="transport"] .js-pricelist-item--number-price', (event: JQueryEventObject) => {
        const target: JQuery = $(event.currentTarget);
        const parentRow: JQuery = target.parents('.js-pricelist-item');
        summary.updateSummaryItem(parentRow.data('service'), 'transportServiceChange', target[0], target.data('amount'));
    });

    $(document).on('click', '.js-confirm-product-grid-options', (event: JQueryEventObject) => {
        const target = $(event.currentTarget);
        summary.changeProductOptions(target);
    });

    $(document).on('submit', '.js-order-form', function (event) {
        event.preventDefault();
        let data = {
            id: $('.js-order-form').data('id'),
            items: JSON.parse(JSON.stringify(summary.getItems())),
            totalPrice: summary.getTotalPrice().toString() + '€',
            funeralType: $('input[name="funeral-type"]').val(),
        };

        $('[type="submit"]', this).addClass('is-loading');
        Ajax.post({
            action: 'confirm-order',
            data: data,
        }).then((result) => {
            window.location.href = result.target_url;
        });
    });

}
