namespace gotoAndPlay {

    $(function() {
        $(document).on('change', '.js-fileupload input[type="file"]', function(event) {
            let input    = $(event.currentTarget);
            let label    = input.parent().find('.form-file__field');
            let fileName = '';
            if (this.files && this.files.length > 1) {
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            } else if ((event.currentTarget as HTMLInputElement).value) {
                fileName = (event.currentTarget as HTMLInputElement).value.split('\\').pop();
            }
            if (fileName) {
                label.html(fileName);
            } else {
                label.html('');
            }
        });
    });

}
