namespace gotoAndPlay {
    // table actions
    $('.js-table-edit').on('click', () => {
        $(event.currentTarget).parents('.table__form').addClass('edit-is-active');

        datepickerInit();
    });

    $('.js-table-cancel').on('click', () => {
        $(event.currentTarget).parents('.table__form').removeClass('edit-is-active');
    });

    $('.js-variable-table-edit').on('click', () => {
        $(event.currentTarget).parents('.table__variable-form').addClass('edit-is-active');

        datepickerInit();
    });

    $('.js-variable-table-cancel').on('click', () => {
        $(event.currentTarget).parents('.table__variable-form').removeClass('edit-is-active');
    });

    $(window).on('resize', () => {
        setTableWidth();
    });

    function setTableWidth(): void {
        const tables: JQuery = $(document).find('.table__variable-table');
        const tabs: JQuery = $('.admin-order__tabs');

        if (tabs.parents('.wrap').length > 0) {
            tables.width(tabs.parents('.wrap').outerWidth());
        }
    }

    $(document).ready(setTableWidth);

}
