namespace gotoAndPlay {

    // Wordpress variables
    export let offices: any;

    export class Map {

        static maps: any   = [];
               element: JQuery;
               latLng: google.maps.LatLng;
               maxZoom: number;
               centerMarker: boolean;
               gmap: any;
               markers: any;
               controls: any;
               container: JQuery;
               styles: any = [{
                   featureType: 'administrative',
                   elementType: 'labels.text.fill',
                   stylers: [{color: '#a1826f'}],
               }, {featureType: 'landscape', elementType: 'all', stylers: [{color: '#fef9f7'}]}, {
                   featureType: 'poi',
                   elementType: 'all',
                   stylers: [{visibility: 'off'}],
               }, {
                   featureType: 'road',
                   elementType: 'all',
                   stylers: [{saturation: -100}, {lightness: 0}, {color: '#efd3c2'}],
               }, {
                   featureType: 'road',
                   elementType: 'labels',
                   stylers: [{lightness: 0}],
               }, {
                   featureType: 'road',
                   elementType: 'labels.text.fill',
                   stylers: [{color: '#a1826f'}],
               }, {
                   featureType: 'road',
                   elementType: 'labels.text.stroke',
                   stylers: [{color: '#ffffff'}],
               }, {featureType: 'road.highway', elementType: 'all', stylers: [{visibility: 'simplified'}]}, {
                   featureType: 'road.arterial',
                   elementType: 'labels.icon',
                   stylers: [{visibility: 'off'}],
               }, {featureType: 'transit', elementType: 'all', stylers: [{visibility: 'off'}]}, {
                   featureType: 'water',
                   elementType: 'all',
                   stylers: [{color: '#f9decc'}, {visibility: 'on'}],
               }];

        constructor(target: HTMLElement) {
            this.element      = $(target);
            this.container    = this.element.parent();
            this.latLng       = new google.maps.LatLng(parseFloat(this.element.data('lat')), parseFloat(this.element.data('lng')));
            this.maxZoom      = parseInt(this.element.data('zoom'));
            this.centerMarker = this.element.data('marker');
            this.controls     = this.element.data('controls');
            this.markers      = [];
            if (!this.controls) {
                this.controls = {};
            }

            this.init();
        }

        init() {
            this.createMap();
        }

        createMap() {
            this.gmap = new google.maps.Map(this.element[0], {
                center: this.latLng,
                disableDefaultUI: true,
                zoomControl: this.controls.zoom,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
                draggable: true,
                styles: this.styles,
                scrollwheel: true,
                zoom: this.maxZoom,
            });

            Map.maps.push({
                map: this.gmap,
                center: this.latLng,
                centerMarker: this.centerMarker,
                onLoad: this.loadMarkers.bind(this),
            });
            this.loadMarkers();
        }

        loadMarkers() {
            // clear current markers
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = [];

            // set new markers
            if (gotoAndPlay.offices !== undefined) {
                for (const office of gotoAndPlay.offices) {
                    this.addMarker(office);
                }
            } else if (this.centerMarker) {
                this.addMarker({
                    title: '',
                    content: '',
                    location: {
                        lat: this.latLng.lat(),
                        lng: this.latLng.lng(),
                    },
                });
            }

            // fit markers to map
            if (this.markers.length > 1) {
                const bounds = new google.maps.LatLngBounds();
                for (let j = 0; j < this.markers.length; j++) {
                    bounds.extend(this.markers[j].getPosition());
                }
                google.maps.event.addListenerOnce(this.gmap, 'zoom_changed', this.setDefaultZoom.bind(this));
                this.gmap.fitBounds(bounds);
            } else {
                this.gmap.setCenter(this.latLng);
            }
        }

        addMarker(markerBase: any) {
            if (markerBase && markerBase.location) {
                const newMarker  = [
                    {
                        title: markerBase.title,
                        content: markerBase.content,
                        latitude: markerBase.location.lat,
                        longtitude: markerBase.location.lng,
                        icon: gotoAndPlay.templatePath + '/inc/img/map-marker.svg',
                        iconWidth: 23,
                        iconHeight: 33,
                    },
                ];
                const halfHeight = newMarker[0].iconHeight / 2 | 0;
                const halfWidth  = newMarker[0].iconWidth / 2 | 0;

                const image = {
                    url: newMarker[0].icon,
                    size: new google.maps.Size(newMarker[0].iconWidth, newMarker[0].iconHeight),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(halfWidth, newMarker[0].iconHeight),
                    scaledSize: new google.maps.Size(newMarker[0].iconWidth, newMarker[0].iconHeight),
                };

                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(newMarker[0].latitude, newMarker[0].longtitude),
                    // title: title,
                    map: this.gmap,
                    icon: image,
                    optimized: false,
                    visible: true,
                });

                this.markers.push(marker);
            }
        }

        setDefaultZoom() {
            if (this.gmap.getZoom() > this.maxZoom) {
                this.gmap.setZoom(this.maxZoom);
            }
        }

        static reload() {
            for (let i = 0; i < Map.maps.length; i++) {
                google.maps.event.trigger(Map.maps[i].map, 'resize');
                Map.maps[i].map.setCenter(Map.maps[i].center);
                if (Map.maps[i].onLoad) {
                    Map.maps[i].onLoad();
                }
            }
        }

        static getDistance(placeObject, address = null) {
            if (address != null) {
                setTimeout(function () {
                    if (!autocompleteEvent) {
                        let geocoder = new google.maps.Geocoder();
                        geocoder.geocode({address: address}, function (results, status) {
                            let placeAddress = results[0];
                            return Map.distanceQuery(placeAddress);
                        });
                    }
                    autocompleteEvent = false;
                }, 500);
            } else {
                return this.distanceQuery(placeObject);
            }
        }

        private static distanceQuery(place: google.maps.GeocoderResult) {
            if (place !== undefined) {
                const municipalityComponent = place['address_components']
                    .filter((component) => component.types.includes('locality'));
                const municipality = municipalityComponent.length ? municipalityComponent[0].long_name.toLowerCase() : null;
                let distantMatrixService = new google.maps.DistanceMatrixService();
                if (municipality == officeCity) {
                    $('.js-distance').val(null);
                    $('.js-transport-service').val(officeCity).trigger('change');
                    if (!$('body').hasClass('page-template-template-order')) {
                        $('.js-google-autocomplete').removeClass('is-loading');
                    }
                } else {
                    let geocoder = new google.maps.Geocoder();
                    geocoder.geocode({address: officeCity}, function (results, status) {
                        let cityCoordinates = results[0]['geometry']['location'];
                        if (officeCity == 'tallinn') {
                            let data = {
                                action: 'get-current-office-address',
                                city: officeCity,
                            };
                            distantMatrixService.getDistanceMatrix(
                                {
                                    origins: [place['formatted_address']],
                                    destinations: [officeAddress],
                                    travelMode: google.maps.TravelMode.DRIVING,
                                }, function (resp, stat) {
                                    if (resp.rows[0].elements[0].distance && resp.rows[0].elements[0].distance.value < 20000) {
                                        $('.js-distance').val(null);
                                        $('.js-transport-service').val(officeCity).trigger('change');
                                    } else {
                                        let circle = new google.maps.Circle({center: cityCoordinates, radius: 40000});
                                        let circleArea = circle.getBounds();
                                        if (circleArea.contains({
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                        })) {
                                            $('.js-distance').val(null);
                                            $('.js-transport-service').val('harjumaa').trigger('change');
                                        } else if (resp.rows[0].elements[0].distance) {
                                            $('.js-distance').val(resp.rows[0].elements[0].distance.value);
                                            $('.js-transport-service').val('long-distance').trigger('change');
                                        } else {
                                            $('.js-distance').val(null);
                                            $('.js-transport-service').val(null).trigger('change');
                                        }
                                    }

                                    if (!$('body').hasClass('page-template-template-order')) {
                                        $('.js-google-autocomplete').removeClass('is-loading');
                                    }
                                },
                            );
                        } else {
                            distantMatrixService.getDistanceMatrix(
                                {
                                    origins: [place['formatted_address']],
                                    destinations: [officeAddress],
                                    travelMode: google.maps.TravelMode.DRIVING,
                                }, function (resp, stat) {
                                    if (resp.rows[0].elements[0].distance) {
                                        let distance = resp.rows[0].elements[0].distance.value;
                                        switch (true) {
                                            case (distance <= 25000):
                                                $('.js-distance').val(0);
                                                $('.js-transport-service').val('25').trigger('change');
                                            case (distance <= 50000):
                                                $('.js-distance').val(0);
                                                $('.js-transport-service').val('50').trigger('change');
                                            case (distance <= 75000):
                                                $('.js-distance').val(0);
                                                $('.js-transport-service').val('75').trigger('change');
                                            default:
                                                $('.js-distance').val(distance);
                                                $('.js-transport-service').val('long-distance-tartu').trigger('change');
                                        }
                                    } else {
                                        $('.js-distance').val(null);
                                        $('.js-transport-service').val(null).trigger('change');
                                    }

                                    if (!$('body').hasClass('page-template-template-order')) {
                                        $('.js-google-autocomplete').removeClass('is-loading');
                                    }
                                },
                            );
                        }
                    });
                }
            } else {
                if ($('body').hasClass('page-template-template-order')) {
                    const parentRow: JQuery = $('.js-transport-service').parents('.js-pricelist-item');
                    $('.js-distance').val(0);
                    if (officeCity == 'tallinn') {
                        $('.js-transport-service').val('long-distance').trigger('change');
                    } else {
                        $('.js-transport-service').val('long-distance-tartu').trigger('change');
                    }
                }
            }
        }
    }

    /**
     * @brief      Triggers on document ready
     */
    function onInit(): void {
        if (((window as any).google === undefined || (window as any).google.maps === undefined) && ($('.js-google-map').length > 0 || $('.js-google-autocomplete').length > 0)) {
            const scriptId = 'google-maps-loader';
            let script     = document.getElementById(scriptId) as HTMLScriptElement;
            if (!script) {
                script                           = document.createElement('script');
                script.id                        = scriptId;
                script.type                      = 'text/javascript';
                script.async                     = true;
                script.defer                     = true;
                script.src                       = 'https://maps.googleapis.com/maps/api/js?key=' + gotoAndPlay.googleMapsApiKey + '&callback=googleMapsLoader&libraries=places';
                (window as any).googleMapsLoader = function() {
                    onInit();
                };
                document.body.appendChild(script);
            }
        } else {
            $('.js-google-map').each(function() {
                const map = new Map(this);
            });
            $('.js-google-autocomplete input').each(function() {
                const autocomplete = new google.maps.places.Autocomplete(this);
                google.maps.event.addListener(autocomplete, 'place_changed', function () {
                    $(this).parents('.js-google-autocomplete').addClass('is-loading');
                    if ($('body').hasClass('page-template-template-order')) {
                        $('.js-confirm-order').prop('disabled', true).addClass('is-disabled');
                    }
                    autocompleteEvent = true;
                    let place = autocomplete.getPlace();
                    Map.getDistance(place);
                });
                $(this).on('change', (event) => {
                    $(this).parents('.js-google-autocomplete').addClass('is-loading');
                    if ($('body').hasClass('page-template-template-order')) {
                        $('.js-confirm-order').prop('disabled', true).addClass('is-disabled');
                    }
                    Map.getDistance(null, $(this).val());
                });
            });

        }
    }

    let autocompleteEvent: boolean = false;
    const officeAddress = $('input[name="officeAddress"]').val();
    const officeCity = $('input[name="officeCity"]').val();
    $(onInit);

}
