// import('import/js/semantic.transition.min.js');
// import('import/js/semantic.dropdown.min.js');

namespace gotoAndPlay {

    export class Dropdown { }

    $(function () {
        if (!Helpers.isMobileDevice) {
            // activate component when not mobile device. Mobile devices use natice select
            $('.dropdown').dropdown({
                silent: true,
            });
        } else {
            $('.dropdown').addClass('is-default');
        }
    });
}
