namespace gotoAndPlay {
    if ($('.c-description').length > 0) {
        function initEditor() {
            let editableTextarea = $(this).find('.c-description__editor-textarea');
            editableTextarea.find('.form-textfield').removeClass('h-hidden');
            let editableInput = editableTextarea.find('.form-textfield__input');
            let temp = editableInput.focus().val(); editableInput.val('').val(temp);
            let editorText = $(this).find('.c-description__editor-text');
            editorText.addClass('h-hidden');
            $(editableInput).focusout(function() {
                let value = editableTextarea.find('.form-textfield__input').val();
                if (value != '') {
                    editableTextarea.find('.form-textfield').addClass('h-hidden');
                    editorText.find('.c-description__editor-value').html(editableTextarea.find('.form-textfield__input').val());
                    editorText.removeClass('h-hidden');
                }
            });
        }
        $(document).ready(function() {
            $('.c-description__editor').click(initEditor);
        });
    }
}
