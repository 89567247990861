// import('import/js/slick.js');

namespace gotoAndPlay {

    $(function () {
        $('.js-carousel').each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                $(this).slick({
                    dots: false,
                    prevArrow: '<button type="button" class="button button--white carousel__nav-item carousel__nav-item--prev"><svg class="icon"><use xlink:href="' + gotoAndPlay.svgPath + '#arrow-right"></use></svg></button>',
                    nextArrow: '<button type="button" class="button button--white carousel__nav-item carousel__nav-item--next"><svg class="icon"><use xlink:href="' + gotoAndPlay.svgPath + '#arrow-right"></use></svg></button>',
                    arrows: true,
                    appendArrows: '.carousel__nav',
                    infinite: false,
                    speed: 300,
                    slidesToShow: 1,
                    adaptiveHeight: true,
                    mobileFirst: true,
                    centerMode: false,
                });
            }
        });
    });
}
