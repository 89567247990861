namespace gotoAndPlay {
    function tourInit() {
        $('.js-tour:visible').each(function() {
            let playButton      = $('.js-play-tour');
            let iframeContainer = $('.tour__frame');

            iframeContainer.hide();
            playButton.on('click', function() {
                iframeContainer.show();
            });
        });
    }

    tourInit();
    $(document).on('reload', tourInit);
}
