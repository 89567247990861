namespace gotoAndPlay {

    const animationSpeed: number = 300;
    const isSmallScreen: () => boolean = () => window.matchMedia(`(max-width: ${Helpers.bp.md - 1}px)`).matches;

    export class NavigationItem {

        isOpen: boolean = false;
        link: JQuery;
        list: JQuery;
        element: JQuery;

        constructor(target: HTMLElement) {

            this.element = $(target);

            this.link = this.element.children('.navigation__link');
            this.list = this.element.children('.navigation__list');
            this.handleOutsideClick = this.handleOutsideClick.bind(this);

            this.element.on('keyup.navigationItem', this.handleKeyup.bind(this));
            this.link.on('click', this.toggle.bind(this));
        }

        open(): void {
            this.isOpen = true;

            if (isSmallScreen()) {
                this.openWithAnimation();
            } else {
                this.finishOpen();
            }

            $(document).on('click.navigationItem', this.handleOutsideClick);
        }

        finishOpen(): void {
            this.element.addClass('is-open');
            this.element.children('.navigation__link').attr('aria-expanded', 'true');
        }

        openWithAnimation(): void {
            const dropdownHeight: number = this.list[0].scrollHeight;

            this.list.stop().animate({
                height: dropdownHeight,
            }, animationSpeed, (): void => {
                this.list.removeAttr('style');
                this.finishOpen();
            });
        }

        close(shouldFocusAfterClose: boolean): void {
            this.isOpen = false;

            // on small screens, close with slide animation
            if (isSmallScreen()) {
                this.closeWithAnimation(shouldFocusAfterClose);
            } else {
                this.finishClose(shouldFocusAfterClose);
            }

            $(document).off('click.navigationItem', this.handleOutsideClick);
        }

        closeWithAnimation(shouldFocusAfterClose: boolean): void {
            this.list.stop().animate({
                height: 0,
            }, animationSpeed, (): void => {
                this.list.removeAttr('style');
                this.finishClose(shouldFocusAfterClose);
            });
        }

        finishClose(shouldFocus: boolean): void {
            this.element.removeClass('is-open');
            this.element.children('.navigation__link').attr('aria-expanded', 'false');
            if (shouldFocus) {
                this.element.children('.navigation__link').focus();
            }
        }

        toggle(event: JQueryEventObject): void {
            event.preventDefault();

            if (this.isOpen) {
                this.close(false);
            } else {
                this.open();
            }
        }

        handleOutsideClick(event: JQueryMouseEventObject): void {
            if (!isSmallScreen()) {
                if ($(event.target).closest(this.element).length === 0) {
                    this.close(false);
                }
            }
        }

        handleKeyup(event: KeyboardEvent): void {
            if (event.key === 'Escape') {
                this.close(true);
            }
        }
    }

    $(function () {
        $('.navigation__item--with-child').each(function () {
            const navigationItem = new NavigationItem(this);
        });
    });
}
