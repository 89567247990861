namespace gotoAndPlay {

    class Pricelist {

        static updatePricelist() {
            $('.js-pricelist-item').each(function() {
                const checkbox = $(this).find('[type="checkbox"][data-type="transport"]');
                if ($('[type="checkbox"]', this).is(':checked')) {
                    if ($('.js-form-select select', this).length) {
                        let option = $('.js-form-select select option:selected', this);
                        if (option.length) {
                            $('.js-pricelist-item--number-price', this).html(option.attr('data-price'));
                        }
                    } else if ($('.js-form-textfield--number', this).length) {
                        let unitPrice = parseFloat($(this).data('price'));
                        let amount    = 0;
                        if ($('.js-form-textfield__input--number', this).length) {
                            amount = parseFloat($('.js-form-textfield__input--number', this).val());
                        }
                        $('.js-pricelist-item--number-price', this).html(parseFloat( (unitPrice * amount).toFixed(2) ).toString() + '€');
                    }
                } else {
                    if (!checkbox.length) {
                        $('.js-pricelist-item--number-price', this).html($('.js-pricelist-item--number-price', this).data('default'));
                    }
                }

                if (checkbox.length) {
                    const addressField = $(this).find('input[name=body_address]');
                    if (checkbox.is(':checked')) {
                        addressField.attr('required', 'required').parents('.form-textfield').removeClass('h-hidden');
                    } else {
                        addressField.removeAttr('required').parents('.form-textfield').addClass('h-hidden');
                    }
                }
            });
        }

        static checkSelects() {
            $('.js-pricelist-item').each(function() {
                let check      = $(this).find('.js-pricelist__table-check');
                let select     = $(this).find('.js-pricelist__table-select');
                let checkInput = check.find('input');
                let isChecked  = checkInput.is(':checked');

                if (!isChecked) {
                    select.hide();
                }

                check.change(function() {
                    checkInput = $(this).find('input');
                    isChecked  = checkInput.is(':checked');
                    if (isChecked) {
                        select.show();
                    } else {
                        select.hide();
                    }
                });
            });
        }

    }

    Pricelist.checkSelects();
    Pricelist.updatePricelist();
    $(document).on('reload', Pricelist.checkSelects);
    $(document).on('change', '.js-pricelist-item select, .js-pricelist-item input', Pricelist.updatePricelist);
    $(document).on('keydown', '.js-google-autocomplete', () => {
        $('.js-confirm-order').prop('disabled', true).addClass('is-disabled');
    });
    $(document).on('focusout', '.js-google-autocomplete', () => {
        if ($('.js-google-autocomplete input').val() == '') {
            $('.js-confirm-order').prop('disabled', false).removeClass('is-disabled');
        }
    });

}
