// import('import/js/perfect-scrollbar.jquery.js');

namespace gotoAndPlay {

    $(function() {
        function initPerfectScrollbar() {
            $('.js-perfect-scrollbar').each(function() {
                $(this).perfectScrollbar('destroy');
                $(this).perfectScrollbar({
                    wheelPropagation: false,
                });
            });
        }

        $(document).on('load reload', initPerfectScrollbar);
    });

}
