namespace gotoAndPlay {

    interface IHeaderSettings {
        headerClass?: string;
        headerFillClass?: string;
        menuOpenClass?: string;
    }

    export class Header {
        settings: IHeaderSettings;
        lastY: number;
        hasBeenTrigged: boolean;
        isTransparent: boolean;

        constructor(target: HTMLElement) {
            this.settings = {
                headerClass: 'header',
                headerFillClass: 'header--filled',
                menuOpenClass: 'h-menu-open',
            };

            this.lastY = $(window).scrollTop();
            this.hasBeenTrigged = false;
            this.isTransparent = $('.' + this.settings.headerClass).hasClass('header--transparent');

            this.init();
        }

        init(): void {
            $(document).on('click', '.js-open-menu', function (event) {
                event.preventDefault();
                event.stopPropagation();
                $('body').toggleClass('h-menu-open');
            });

            $(window).on('scroll', this.scrollHead.bind(this));
        }

        scrollHead(): void {
            // get current scroll position
            const currY = $(window).scrollTop();

            if (currY >= 10 && !this.hasBeenTrigged) {
                this.hasBeenTrigged = true;
                $('.' + this.settings.headerClass).addClass(this.settings.headerFillClass);
            }
            if (currY < 10) {
                $('.' + this.settings.headerClass).removeClass(this.settings.headerFillClass);
                this.hasBeenTrigged = false;
            }

            // determine current scroll direction
            const y = (currY > this.lastY) ? 'down' : ((currY === this.lastY) ? 'none' : 'up');

            if (y === 'down' && currY >= 70) {
                $('.' + this.settings.headerClass).addClass('header--hidden');
                $('.' + this.settings.headerClass).removeClass('header--fixed');
            } else if (y === 'up') {
                $('.' + this.settings.headerClass).removeClass('header--hidden');
                $('.' + this.settings.headerClass).addClass('header--fixed');
            }

            // update last scroll position to current position
            this.lastY = currY;
        }
    }

    $(function () {
        $('.js-menu').each(function () {
            const header = new Header(this);
        });
    });

}
