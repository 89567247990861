namespace gotoAndPlay {

    function getSelects(): any {
        let fancySelect: any;
        if (isAdmin) {
            fancySelect = $('.admin-dashboard select, .modaal__content select').not(':disabled, .form-textfield__input--default, [data-default], :hidden');
        } else {
            if (!Helpers.isMobileDevice) {
                // enhance select component by default when not mobile device
                fancySelect = $('select').not(':disabled, .form-textfield__input--default, [data-default], :hidden');
            } else {
                // however one might want to have fancy selects on mobile devices
                fancySelect = $('.form-select--enhance select').not(':hidden');
            }
        }

        return fancySelect;
    }

    export function initSelect() {
        const selects: any = getSelects();
        selects.each(function() {
            const select = $(this).parents('.form-select');
            if (!select.data('dropdown')) {
                select.data('dropdown', select.html());
            }
        });
        selects.dropdown();
    }

    export function destroySelect() {
        $('select.is-inited').each(function() {
            const select = $(this).parents('.form-select');
            $(this).removeClass('is-inited');
            if (select.data('dropdown')) {
                select.html(select.data('dropdown'));
            }
        });
    }

    $(function() {

        $(document).on('reload', initSelect);
        $(document).on('beforeReload', destroySelect);
        initSelect();

    });
}
