// import('import/js/fancybox.js');

namespace gotoAndPlay {
    export interface IButton {
        text: string;
        icon?: string;
        link?: string;
        iconPosition?: string;
        attributes?: string;
        size?: 'small' | 'tiny';
        iconAnimated?: boolean;
    }

    export interface IButtonProps {
        data: IButton;
        className?: string;
        iconClassName?: string;
    }

    function renderButton(props: IButtonProps): string {
        const { data, className, iconClassName }: IButtonProps = props;
        const element: string = data.link ? 'a' : 'button';
        const icon: string = data.icon ? renderIcon(data.icon, '') : '';
        const classArray: string[] = ['button'];

        if (className) {
            classArray.push(className);
        }

        if (data.icon && !data.iconPosition) {
            classArray.push('button--icon');
        }

        if (data.size) {
            classArray.push(`button--icon-right-${data.size}`);
        }

        if (data.iconAnimated) {
            classArray.push('button--icon-animated');
        }

        if (data.icon && data.iconPosition === 'right') {
            classArray.push('button--icon-right');
        }

        if (data.icon && data.iconPosition === 'left') {
            classArray.push('button--icon-left');
        }

        return `<${element} class="${classArray.join(' ')}" ${data.link ? `href="${data.link}"` : 'type="button"'}${data.attributes ? ` ${data.attributes}` : ''}><span class="button__inner">${icon}<span class="button__text">${data.text}</span></span></${element}>`;
    }

    function renderIcon(name: string, modifier?: string, className?: string): string {
        const use: string = '<use xlink:href="' + gotoAndPlay.svgPath + '#' + name + '"></use>';
        const classArray: string[] = ['icon'];

        if (modifier) {
            classArray.push(modifier);
        }

        if (className) {
            classArray.push(className);
        }

        return '<svg class="' + classArray.join(' ') + '">' + use + '</svg>';
    }

    $.fancybox.defaults = {
        ...$.fancybox.defaults,
        animationDuration: 800,
        animationEffect: 'fade',
        baseTpl:
            '<div class="fancybox-container lightbox" role="dialog" tabindex="-1">' +
            '<div class="fancybox-bg"></div>' +
            '<div class="fancybox-inner">' +
            '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
            '<div class="fancybox-toolbar">{{buttons}}</div>' +
            '<div class="fancybox-navigation">{{arrows}}</div>' +
            '<div class="fancybox-stage"></div>' +
            '<div class="fancybox-caption"><div class="fancybox-caption__body"></div></div>' +
            '</div>' +
            '</div>',
        btnTpl: {
            arrowLeft: renderButton({
                className: 'lightbox__nav-item lightbox__nav lightbox__nav--left',
                data: {
                    attributes: 'data-fancybox-prev',
                    icon: 'arrow-right',
                    text: '',
                },
                iconClassName: 'lightbox__nav-icon',
            }),
            arrowRight: renderButton({
                className: 'lightbox__nav-item lightbox__nav lightbox__nav--right',
                data: {
                    attributes: 'data-fancybox-next',
                    icon: 'arrow-right',
                    text: '',
                },
                iconClassName: 'lightbox__nav-icon',
            }),
            close: renderButton({
                className: 'lightbox__nav-item lightbox__close',
                data: {
                    attributes: 'data-fancybox-close',
                    icon: 'close-slim',
                    text: '',
                },
                iconClassName: 'lightbox__close-icon',
            }),
        },
        buttons: [
            'close',
        ],
        clickContent: false,
        hash: false,
        idleTime: 0,
        infobar: false,
        loop: true,
        smallBtn: false,
        /*spinnerTpl: Spinner.render({
            className: 'lightbox__spinner',
        }),*/
        thumbs: {
            ...$.fancybox.defaults.thumbs,
            autoStart: true,
            axis: 'x',
        },
        toolbar: true,
    };
}
